<template>
  <h3 class="sub-title">{{ name }}</h3>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-title {
  font-weight: 700;
  line-height: 1.3;
  font-size: 1.4rem;
  margin: 0 0 10px 0;
}

@media (max-width: 1100px) {
  .sub-title {
    margin: 20px 0;
  }
}
</style>
