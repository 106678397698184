<template>
  <div class="about">
    <section>
      <content-title name="About me" />
      <bar />
      <p>
        My passion is all around my own limits. I keep learning and working hard
        to overcome them. It is amazing, the amount of knowledge you get, when
        you are part of technological projects from the beginning. Your mind is
        constantly blowing with different kind of ideas, problems and needs. So
        the huge challenge is to collect them, and build an scalable ecosystem
        that satisfies the business.
      </p>
      <p>
        I hard believe in the power of programming. Is incredible how you can
        transform and improve the lives of people around the world. Also, as an
        Engineer, the main thing is to find the real problems and needs. And
        give solutions which can be measure and generate impact.
      </p>
      <p>
        I define myself as a person who is perseverance and loves the
        challenges. As a positive aspect, my motivation allows me to never hold
        back.
      </p>
    </section>
    <section class="what-i-am-doing">
      <content-sub-title name="What I'm Doing" />
      <div class="info-boxes">
        <info-box
          icon="el-icon-edit"
          title="Web development"
          description="High quality and professional code."
        />
        <info-box
          icon="el-icon-edit"
          title="Infrastructure"
          description="AWS is powerful. They give you all the solutions you need to scale you business."
        />
        <info-box
          icon="el-icon-edit"
          title="Knowledge"
          description="Sharing knowledge to others is very important. The benefits are huge."
        />
        <info-box
          icon="el-icon-edit"
          title="Analytics"
          description="Manage Big Data so business people can see what is happening, and can make good decisions."
        />
      </div>
    </section>
    <section>
      <footer-content />
    </section>
  </div>
</template>

<script>
import ContentTitle from "@/components/contents/Title";
import ContentSubTitle from "@/components/contents/SubTitle";
import Bar from "@/components/contents/Bar.vue";
import InfoBox from "@/components/infoBox/InfoBox.vue";
import FooterContent from "@/components/contents/Footer.vue";

export default {
  name: "About",
  components: {
    ContentTitle,
    ContentSubTitle,
    Bar,
    InfoBox,
    FooterContent,
  },
};
</script>

<style lang="scss" scoped>
.about {
  section {
    padding: 0 4%;
  }
  .what-i-am-doing {
    background-color: $grey;
    padding: 3% 4%;

    h2 {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 1.5rem;
    }

    .info-boxes {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
</style>
